<template>
  <div>
   <video-player :images="getImages" ></video-player>
  </div>
</template>

<script>
import videoPlayer from "@/layouts/components/videoPlayer"
export default {
  name: 'VagsisNewTestProfile',
components: {
    videoPlayer
},
  data() {
    return {
      getImages:[
        { id: 0, active:0,url: ['https://maminmagazinchik.ru/wp-content/uploads/1/8/5/185a2434f732af747241d50fd1dfa3b1.png', 'https://picsum.photos/200/300?random=2', 'https://picsum.photos/200/300?random=3'], date: '2022-01-01' },
        { id: 1, active:1,url: ['https://picsum.photos/200/300?random=2', 'https://picsum.photos/200/300?random=3'], date: '2022-01-02' },
        { id: 2,active:0, url: ['https://picsum.photos/200/300?random=3', 'https://picsum.photos/200/300?random=4'], date: '2022-01-03' },
        { id: 3,active:1,url: ['https://picsum.photos/200/300?random=4', 'https://picsum.photos/200/300?random=5'], date: '2022-01-04' },
      ]
    }
  },

  mounted() {
    
  },

  methods: {
    // updateData(newData) {
    //   this.data = newData;
    // }
  }
}
</script>